<template>
  <div>
    <v-menu
      v-model="picker"
      :close-on-content-click="false"
      nudge-bottom="40"
      bottom
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <validation-observer ref="datePicker">
          <validation-provider
            v-slot="{errors}"
            name="Data"
            rules=""
            vid="date"
          >
            <v-text-field
              ref="data"
              v-model="valor"
              v-mask="mascaraData"
              :autofocus="autofocus"
              :append-icon="!valor ? icons.mdiCalendarMonthOutline : ''"
              :clearable="!!valor"
              :disabled="disabled"
              :error-messages="[ ...errors, ...parentErrors]"
              :filled="filled"
              :hide-details="hideDetails"
              :label="label"
              :outlined="outlined"
              :readonly="readonly"
              :solo="solo"
              :dense="dense"
              @change="autocomplete('tab')"
              @click:append="picker = true"
              @click:clear="resetData()"
              @keydown.enter="autocomplete(), $emit('listar')"
              @keydown.tab="autocomplete('tab')"
            >
              <template
                v-if="!valor"
                v-slot:append
              >
                <slot name="append" />
                <div v-if="controlarDia">
                  <v-icon
                    v-if="valor"
                    @click="diminuirDia()"
                  >
                    {{ icons.mdiChevronLeft }}
                  </v-icon>
                  <v-icon
                    v-if="valor"
                    @click="aumentarDia()"
                  >
                    {{ icons.mdiChevronRight }}
                  </v-icon>
                  <v-icon v-on="on">
                    {{ icons.mdiCalendarMonthOutline }}
                  </v-icon>
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </validation-observer>
      </template>
      <v-date-picker
        v-model="data"
        :disabled="disabled"
        :max="max"
        :min="min"
        :type="type === 'datetime' ? 'date' : type"
        color="secondary"
        locale="pt-br"
        no-title
        @input="formatarDataPT($event)"
      >
        <v-btn
          v-if="btnNow"
          block
          color="secondary"
          dark
          outlined
          @click="formatarDataPT($day().format('YYYY-MM-DD HH:mm'))"
        >
          Hoje - {{ $day().format(tipoPT) }}
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiCalendarMonthOutline,
} from '@mdi/js'

export default {
  name: 'ComponenteData',

  setup() {
    return {
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiCalendarMonthOutline,
      },
    }
  },

  props: {
    autofocus: {
      default: false,
      type: Boolean,
    },
    btnNow: {
      default: true,
      type: Boolean,
    },
    controlarDia: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    filled: {
      default: false,
      type: Boolean,
    },
    hideDetails: {
      default: false,
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    min: {
      default: '',
      type: String,
    },
    max: {
      default: '',
      type: String,
    },
    outlined: {
      default: false,
      type: Boolean,
    },
    dense: {
      default: true,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    solo: {
      default: false,
      type: Boolean,
    },
    type: {
      default: 'date',
      type: String,
    },
    value: {
      required: true,
      validator(value) {
        return (
          typeof value === 'undefined'
          || typeof value === 'string'
          || value === null
        )
      },
    },
  },

  data() {
    return {
      data: null,
      picker: false,
      valor: '',
    }
  },

  computed: {
    mascaraData() {
      let formato

      switch (this.type) {
        case 'date':
          formato = '##/##/####'
          break
        case 'datetime':
          formato = '##/##/#### ##:##'
          break
        case 'month':
          formato = '##/####'
          break
        default:
          formato = '##/##/####'
          break
      }

      return formato
    },
    parentErrors() {
      if (this.$attrs['error-messages']) {
        return this.$attrs['error-messages']
      }

      return []
    },
    tipoEN() {
      let tipo

      if (this.type === 'date') tipo = 'YYYY-MM-DD'
      else if (this.type === 'datetime') tipo = 'YYYY-MM-DD HH:mm'
      else if (this.type === 'month') tipo = 'YYYY-MM'

      return tipo
    },
    tipoPT() {
      let tipo

      if (this.type === 'date') tipo = 'DD/MM/YYYY'
      else if (this.type === 'datetime') tipo = 'DD/MM/YYYY HH:mm'
      else if (this.type === 'month') tipo = 'MM/YYYY'

      return tipo
    },
  },

  watch: {
    value(val) {
      this.valor = val
    },
  },

  created() {
    this.valor = this.value
  },

  destroyed() {
    window.removeEventListener('keydown', this.verificarTeclas)
  },

  mounted() {
    window.addEventListener('keydown', this.verificarTeclas)
  },

  methods: {
    aumentarDia() {
      const valor = this.$day(this.valor, this.tipoPT).add(1, 'day').format(this.tipoPT)

      this.valor = valor
      this.formatarDataEN(valor)
    },
    diminuirDia() {
      const valor = this.$day(this.valor, this.tipoPT).subtract(1, 'day').format(this.tipoPT)

      this.valor = valor
      this.formatarDataEN(valor)
    },
    async autocomplete(key) {
      if (key === 'tab' && !this.valor) return
      if (!this.valor) this.$emit('input', '')

      const today = this.$day().format(this.tipoPT)
      const currentDate = this.valor ? `${this.valor}${[...today].splice(this.valor.length).join('')}` : today
      const data = this.$day(currentDate, this.tipoPT).format(this.tipoEN)

      this.$emit('input', '')
      this.formatarDataPT(data)
    },
    focus() {
      this.$refs.data.focus()
    },
    formatarDataEN(parametro) {
      if (parametro && parametro.length) {
        let length

        if (this.type === 'date') length = 10
        else if (this.type === 'datetime') length = 19
        else if (this.type === 'month') length = 7

        if (parametro.length === length) {
          this.data = this.$day(parametro, this.tipoPT).format(this.tipoEN)
        } else {
          this.data = null
          this.$refs.datePicker.reset()
        }
      }
    },
    formatarDataPT(parametro) {
      if (parametro.length) {
        // eslint-disable-next-line no-param-reassign
        if (this.type === 'month') parametro = parametro.concat('-01')

        this.data = parametro
        this.validarInput(this.$day(parametro).format(this.tipoPT))

        this.picker = false
      }
    },
    verificarTeclas(event) {
      if (this.$refs.data.isFocused && event.keyCode === 114) {
        event.preventDefault()
        this.picker = true
      }
    },
    validate(date, format) {
      return this.$day(date, format).format(format) === date
    },
    validarInput(val) {
      if (val && val.length) {
        if (this.validate(val, this.tipoPT)) {
          this.$emit('input', val)
          this.valor = val
          this.formatarDataEN(val)
          this.$refs.datePicker.reset()
        } else {
          this.$refs.datePicker.setErrors({ date: ['Formato de data inválida'] })
        }
      } else {
        this.resetData()
      }
    },
    resetData() {
      this.$emit('input', '')
      this.valor = ''
      this.data = null
    },
  },
}
</script>
